import $ from 'jquery';

export default () => {
  $(document).scroll(function(){

    if (window.scrollY > $('.section-ani-01').offset().top - window.innerHeight / 2) {
      $(".ani-object-01").addClass("is-animating");
    }

    if (window.scrollY > $('.ani-object-02').offset().top - window.innerHeight / 2) {
      $(".ani-object-02").addClass("is-animating");
    }

    if (window.scrollY > $('.ani-object-03').offset().top - window.innerHeight / 2) {
      $(".ani-object-03").addClass("is-animating");
    }

    if (window.scrollY > $('.ani-object-04').offset().top - window.innerHeight / 2) {
      $(".ani-object-04").addClass("is-animating");
    }

    if (window.scrollY > $('.ani-object-05').offset().top - window.innerHeight / 2) {
      $(".ani-object-05").addClass("is-animating");
    }

    if (window.scrollY > $('.ani-object-06').offset().top - window.innerHeight / 2) {
      $(".ani-object-06").addClass("is-animating");
    }

  });
};
