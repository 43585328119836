import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import TopNav from './top-nav';

const Header = ({ siteTitle }) => (
  <header id="bg-main-01" className="main-header">
    <div className="container">
      <div className="main-header-child">
        <a href="http://catarinasdesign.com.br/" className="main-header-logo" target="blank">

          <figure
            className="image is-logo-top"
            style={{
              backgroundImage: `url(${require('../images/logos/power-cats.svg')})`
            }}>
            <img src={require('../images/logos/power-cats.svg')} alt="Logo powered by Catarinas" />
          </figure>

        </a>
      </div>

      <TopNav />

    </div>
  </header>
);

export default Header;
