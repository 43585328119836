/**
 * @author Christian Spilhere
 */
export default () => {

  const clickOutside = function() {
    // document.addEventListener('click', (event) => {
    //   let outside = Array(...arguments).filter((el) => {
    //     return event.path.filter((item) => {
    //       if(item.classList) return el === item ? true : false;
    //     })[0];
    //   })[0];
    //   if (typeof arguments[arguments.length - 1] !== 'function') return;
    //   !outside ? arguments[arguments.length - 1]() : null;
    // });
  };

  let getAllToggles = document.querySelectorAll('[data-toggle]');

   if (getAllToggles) {
     Array(...getAllToggles).forEach((element) => {
       let target = element.getAttribute('data-toggle-target');
       let targetElement = document.querySelector(target);
       let clickOutsideEnabled = element.getAttribute('data-toggle-clickoutside');

        if (!targetElement) return;
       console.log(target, targetElement);


       element.addEventListener('click', (event) => {
         event.preventDefault();
         if (element.classList.contains('is-active') || targetElement.classList.contains('is-active')) {
            targetElement.classList.remove('is-active');
         } else {
            targetElement.classList.add('is-active');
         }

         targetElement.addEventListener('click', (event) => {
          if (event.target.classList.contains('click-inside')) {
            targetElement.classList.remove('is-active');
          }
         });

        });
       if (clickOutsideEnabled) {
         clickOutside(element, targetElement, () => {
           targetElement.classList.remove('is-active');
         });
       }
     });
   }


  let getAllElements = document.querySelectorAll('[data-change-state]');

  if (!getAllElements) return;

  Array(...getAllElements).forEach((element) => {
    let triggerGroup = element.getAttribute('data-trigger-group') || 'single';
    let target = element.getAttribute('data-trigger-href');
    // let targetSingle = element.getAttribute('data-trigger-href');
    let eventType = element.getAttribute('data-trigger-event') || 'click';

    element.addEventListener(eventType, (event) => {
      // event.preventDefault();
      changeTriggerState(element, triggerGroup);
      changeTargetState(target);
      // changeMultipleTarget(target);

    });


  });

  /**
   * Change trigger state
   */
  function changeTriggerState(element, group) {
    if (group !== 'single') {
      let elementsGroup = document.querySelectorAll('[data-trigger-group="' + group + '"]');
      Array(...elementsGroup).forEach((groupItem) => {
        groupItem.classList.remove('is-active');
      });
    }
    element.classList.add('is-active');
  }

  /**
   * Change target state
   */
  function changeTargetState(target) {
    let element = document.querySelector(target);
    let targetGroup = element.getAttribute('data-target-group') || 'single';
    if (targetGroup !== 'single') {
      let elementsGroup = document.querySelectorAll('[data-target-group="' + targetGroup + '"]');
      Array(...elementsGroup).forEach((groupItem) => {
        groupItem.classList.remove('is-active');
      });
    }
    element.classList.add('is-active');
  }

};
