import { Link } from 'gatsby';
import React from 'react';

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

const Partial = ({ }) => (
  <>
    <a id="quemsomos"></a>
    <section id="section-catarinas" className="section move-top-desktop-space">

      <img
        src={require('../../images/backgrounds/bg-light-02.png')}
        alt=""
        aria-hidden="true"
        id="bg-catarinas"
        className="bg-section-light-02"
      />

      <div className="container">

        <div className="columns">

          <div className="column is-full-mobile is-half-desktop">
            <figure
              className="image is-title-lg"
              style={{ ...backgroundImage(require('../../images/logos/power-cats.svg')) }}>
              <img src={require('../../images/logos/power-cats.svg')} alt="Logo powered by Catarinas" />
            </figure>
            <div className="v-space-xxxlg visible-desktop-true"></div>
            <div className="v-space-lg visible-desktop-false"></div>
            <p className="text is-size-4">
              A Catarinas é uma Consultoria de UX
              especializada em pesquisa com usuários
              e usabilidade. Atua há 6 anos no mercado
              com grandes empresas em áreas de
              inovação e tecnologia.
            </p>
            <div className="v-space-xxlg visible-desktop-true"></div>
            <div className="v-space-lg visible-desktop-false"></div>
            <a href="http://catarinasdesign.com.br/" className="button is-primary is-rounded is-uppercase is-wide" target="blank">
              <span className="is-uppercase">
                Conheça a Catarinas design
              </span>
            </a>
          </div>

          <div className="column is-full-mobile is-half-desktop">

            <div className="v-space-xxlg visible-desktop-false"></div>

            <div className="media is-v-center">
              <div className="media-left">
                <figure
                  className="image is-avatar-lg"
                  style={{ ...backgroundImage('./images/pics/astro-julia.svg') }}>
                  <img src={require('../../images/pics/astro-julia.svg')} alt="Logo powered by Catarinas" />
                </figure>
              </div>
              <div className="media-content">
                <h3 className="text is-size-4">
                  Julia Ghisi
                </h3>
                <p className="text is-size-5 is-uppercase is-primary">
                  UX Strategist
                </p>

                <div className="v-space visible-desktop-true"></div>
                <div className="v-space-sm visible-desktop-false"></div>

                <div className="social">
                  <a href="https://www.linkedin.com/in/juliaghisi/" className="link-social" title="Linkedin">
                    <svg id="_003-linkedin" data-name="003-linkedin" xmlns="http://www.w3.org/2000/svg" width="18.939" height="18.527" viewBox="0 0 18.939 18.527"><path id="Caminho_1412" data-name="Caminho 1412" d="M4.941,162.431H0V149.668H4.941Zm-4.117-.823H4.117V150.491H.823Zm0,0" transform="translate(0 -143.904)" fill="#fff" /><path id="Caminho_1413" data-name="Caminho 1413" d="M183.02,162.431h-4.941v-7a1.235,1.235,0,0,0-2.47,0v7h-4.941V149.668h4.941v.829a4.37,4.37,0,0,1,2.563-.829,4.847,4.847,0,0,1,4.848,4.833Zm-4.117-.823H182.2V154.5a4.019,4.019,0,0,0-4.025-4.009,3.614,3.614,0,0,0-2.676,1.2l-.71.777v-1.976h-3.294v11.117h3.294v-6.176a2.059,2.059,0,0,1,4.117,0Zm0,0" transform="translate(-164.08 -143.904)" fill="#fff" /><path id="Caminho_1414" data-name="Caminho 1414" d="M2.47,5.273A2.47,2.47,0,1,1,4.941,2.8,2.47,2.47,0,0,1,2.47,5.273Zm0-4.117A1.647,1.647,0,1,0,4.117,2.8,1.647,1.647,0,0,0,2.47,1.156Zm0,0" transform="translate(0 -0.332)" fill="#fff" /></svg>
                  </a>
                  <a href="https://medium.com/@juliaghisi" className="link-social" title="Medium">
                    <svg id="_001-medium" data-name="001-medium" xmlns="http://www.w3.org/2000/svg" width="18.116" height="15.24" viewBox="0 0 18.116 15.24"><path id="Caminho_1406" data-name="Caminho 1406" d="M5.867,15.406a1.127,1.127,0,0,1-.479-.108L.952,13.229A1.652,1.652,0,0,1,0,11.737V1.3A1.132,1.132,0,0,1,1.616.275L6.052,2.344A1.652,1.652,0,0,1,7,3.837V14.274A1.138,1.138,0,0,1,5.867,15.406ZM1.132,1A.309.309,0,0,0,.823,1.3V11.737a.829.829,0,0,0,.474.746l4.436,2.069a.319.319,0,0,0,.443-.278V3.842A.829.829,0,0,0,5.7,3.1L1.266,1.027A.31.31,0,0,0,1.132,1Zm0,0" transform="translate(0 -0.167)" fill="#fff" /><path id="Caminho_1407" data-name="Caminho 1407" d="M176.832,101.175a1.127,1.127,0,0,1-.479-.108l-10.087-4.709.35-.746,10.087,4.709a.319.319,0,0,0,.437-.278V89.379h.823v10.664A1.137,1.137,0,0,1,176.832,101.175Zm0,0" transform="translate(-159.848 -85.935)" fill="#fff" /><path id="Caminho_1408" data-name="Caminho 1408" d="M202.412,13.04l-.685-.458,5.718-8.569a.463.463,0,0,0-.19-.679L202.7,1.208a.776.776,0,0,0-.937.257L198.552,6.3l-.685-.458,3.217-4.833a1.56,1.56,0,0,1,1.292-.695,1.6,1.6,0,0,1,.669.149L207.6,2.587a1.287,1.287,0,0,1,.525,1.884Zm0,0" transform="translate(-190.23 -0.307)" fill="#fff" /><path id="Caminho_1409" data-name="Caminho 1409" d="M156.574,73.874l.715-.409,5.431,9.483-.715.409Zm0,0" transform="translate(-150.531 -70.636)" fill="#fff" /></svg>
                  </a>
                  <a href="#" className="link-social is-hidden" title="Rss">
                    <svg id="_004-rss" data-name="004-rss" xmlns="http://www.w3.org/2000/svg" width="16.881" height="16.881" viewBox="0 0 16.881 16.881"><path id="Caminho_1415" data-name="Caminho 1415" d="M2.059,334.785a2.059,2.059,0,1,1,2.059-2.059A2.059,2.059,0,0,1,2.059,334.785Zm0-3.294a1.235,1.235,0,1,0,1.235,1.235A1.235,1.235,0,0,0,2.059,331.491Zm0,0" transform="translate(0 -317.904)" fill="#fff" /><path id="Caminho_1416" data-name="Caminho 1416" d="M20.961,170.293H16.844v-.412a5.769,5.769,0,0,0-5.764-5.764h-.412V160h.412a9.89,9.89,0,0,1,9.881,9.881Zm-3.3-.824h2.475a9.078,9.078,0,0,0-8.641-8.636v2.475A6.6,6.6,0,0,1,17.657,169.47Zm0,0" transform="translate(-10.256 -153.824)" fill="#fff" /><path id="Caminho_1417" data-name="Caminho 1417" d="M27.137,16.469H23.02v-.412A11.955,11.955,0,0,0,11.08,4.117h-.412V0h.412A16.078,16.078,0,0,1,27.137,16.057Zm-3.3-.824h2.47A15.253,15.253,0,0,0,11.491.829V3.3A12.786,12.786,0,0,1,23.838,15.645Zm0,0" transform="translate(-10.256)" fill="#fff" /></svg>
                  </a>
                  <a href="https://wa.me/554899017765" className="link-social" title="Whatsapp">
                    <svg id="_002-whatsapp" data-name="002-whatsapp" xmlns="http://www.w3.org/2000/svg" width="17.411" height="17.41" viewBox="0 0 17.411 17.41"><path id="Caminho_1410" data-name="Caminho 1410" d="M0,17.411l1-4.936A8.553,8.553,0,0,1,.118,8.646a8.646,8.646,0,1,1,8.646,8.646,8.553,8.553,0,0,1-3.824-.89Zm5.059-1.873.129.067a7.741,7.741,0,0,0,3.577.865A7.823,7.823,0,1,0,.942,8.646a7.741,7.741,0,0,0,.865,3.577l.067.129-.818,4Zm0,0" fill="#fff" /><path id="Caminho_1411" data-name="Caminho 1411" d="M106.447,106.362h-.381a7.007,7.007,0,0,1-7-7v-.381a2.454,2.454,0,0,1,.726-1.745l.34-.34a1.439,1.439,0,0,1,1.987,0l1.065,1.06a1.4,1.4,0,0,1,.412.993,1.415,1.415,0,0,1-.412.993l-.1.1a1.075,1.075,0,0,0-.314.762,1.091,1.091,0,0,0,.314.762l.772.772a1.121,1.121,0,0,0,1.529,0l.1-.1a1.44,1.44,0,0,1,1.987,0l1.065,1.071a1.4,1.4,0,0,1,0,1.987l-.34.34A2.455,2.455,0,0,1,106.447,106.362ZM101.125,97.3a.582.582,0,0,0-.412.17l-.34.34a1.637,1.637,0,0,0-.484,1.163v.386a6.181,6.181,0,0,0,6.176,6.176h.381a1.637,1.637,0,0,0,1.163-.484l.34-.34a.582.582,0,0,0,0-.823l-1.06-1.065a.6.6,0,0,0-.824,0l-.1.1a1.9,1.9,0,0,1-2.692,0l-.772-.772a1.914,1.914,0,0,1-.556-1.348,1.894,1.894,0,0,1,.556-1.343l.1-.1a.587.587,0,0,0,.17-.412.581.581,0,0,0-.17-.412l-1.065-1.065A.582.582,0,0,0,101.125,97.3Zm0,0" transform="translate(-95.242 -92.775)" fill="#fff" /></svg>
                  </a>
                </div>
              </div>
            </div>

            <div className="v-space-xxxlg visible-desktop-true"></div>
            <div className="v-space-lg visible-desktop-false"></div>

            <div className="media is-v-center">
              <div className="media-left">
                <figure
                  className="image is-avatar-lg"
                  style={{ ...backgroundImage('./images/pics/astro-pri.svg') }}>
                  <img src={require('../../images/pics/astro-pri.svg')} alt="Logo powered by Catarinas" />
                </figure>
              </div>
              <div className="media-content">
                <h3 className="text is-size-4">
                  Priscilla Albuquerque
                </h3>
                <p className="text is-size-5 is-uppercase is-primary">
                  UX Strategist
                </p>

                <div className="v-space visible-desktop-true"></div>
                <div className="v-space-sm visible-desktop-false"></div>

                <div className="social">
                  <a href="https://www.linkedin.com/in/priscillaalbuquerque" className="link-social" title="Linkedin">
                    <svg id="_003-linkedin" data-name="003-linkedin" xmlns="http://www.w3.org/2000/svg" width="18.939" height="18.527" viewBox="0 0 18.939 18.527"><path id="Caminho_1412" data-name="Caminho 1412" d="M4.941,162.431H0V149.668H4.941Zm-4.117-.823H4.117V150.491H.823Zm0,0" transform="translate(0 -143.904)" fill="#fff" /><path id="Caminho_1413" data-name="Caminho 1413" d="M183.02,162.431h-4.941v-7a1.235,1.235,0,0,0-2.47,0v7h-4.941V149.668h4.941v.829a4.37,4.37,0,0,1,2.563-.829,4.847,4.847,0,0,1,4.848,4.833Zm-4.117-.823H182.2V154.5a4.019,4.019,0,0,0-4.025-4.009,3.614,3.614,0,0,0-2.676,1.2l-.71.777v-1.976h-3.294v11.117h3.294v-6.176a2.059,2.059,0,0,1,4.117,0Zm0,0" transform="translate(-164.08 -143.904)" fill="#fff" /><path id="Caminho_1414" data-name="Caminho 1414" d="M2.47,5.273A2.47,2.47,0,1,1,4.941,2.8,2.47,2.47,0,0,1,2.47,5.273Zm0-4.117A1.647,1.647,0,1,0,4.117,2.8,1.647,1.647,0,0,0,2.47,1.156Zm0,0" transform="translate(0 -0.332)" fill="#fff" /></svg>
                  </a>
                  <a href="https://medium.com/@priscillaalbuquerque" className="link-social" title="Medium">
                    <svg id="_001-medium" data-name="001-medium" xmlns="http://www.w3.org/2000/svg" width="18.116" height="15.24" viewBox="0 0 18.116 15.24"><path id="Caminho_1406" data-name="Caminho 1406" d="M5.867,15.406a1.127,1.127,0,0,1-.479-.108L.952,13.229A1.652,1.652,0,0,1,0,11.737V1.3A1.132,1.132,0,0,1,1.616.275L6.052,2.344A1.652,1.652,0,0,1,7,3.837V14.274A1.138,1.138,0,0,1,5.867,15.406ZM1.132,1A.309.309,0,0,0,.823,1.3V11.737a.829.829,0,0,0,.474.746l4.436,2.069a.319.319,0,0,0,.443-.278V3.842A.829.829,0,0,0,5.7,3.1L1.266,1.027A.31.31,0,0,0,1.132,1Zm0,0" transform="translate(0 -0.167)" fill="#fff" /><path id="Caminho_1407" data-name="Caminho 1407" d="M176.832,101.175a1.127,1.127,0,0,1-.479-.108l-10.087-4.709.35-.746,10.087,4.709a.319.319,0,0,0,.437-.278V89.379h.823v10.664A1.137,1.137,0,0,1,176.832,101.175Zm0,0" transform="translate(-159.848 -85.935)" fill="#fff" /><path id="Caminho_1408" data-name="Caminho 1408" d="M202.412,13.04l-.685-.458,5.718-8.569a.463.463,0,0,0-.19-.679L202.7,1.208a.776.776,0,0,0-.937.257L198.552,6.3l-.685-.458,3.217-4.833a1.56,1.56,0,0,1,1.292-.695,1.6,1.6,0,0,1,.669.149L207.6,2.587a1.287,1.287,0,0,1,.525,1.884Zm0,0" transform="translate(-190.23 -0.307)" fill="#fff" /><path id="Caminho_1409" data-name="Caminho 1409" d="M156.574,73.874l.715-.409,5.431,9.483-.715.409Zm0,0" transform="translate(-150.531 -70.636)" fill="#fff" /></svg>
                  </a>
                  <a href="#" className="link-social is-hidden" title="Rss">
                    <svg id="_004-rss" data-name="004-rss" xmlns="http://www.w3.org/2000/svg" width="16.881" height="16.881" viewBox="0 0 16.881 16.881"><path id="Caminho_1415" data-name="Caminho 1415" d="M2.059,334.785a2.059,2.059,0,1,1,2.059-2.059A2.059,2.059,0,0,1,2.059,334.785Zm0-3.294a1.235,1.235,0,1,0,1.235,1.235A1.235,1.235,0,0,0,2.059,331.491Zm0,0" transform="translate(0 -317.904)" fill="#fff" /><path id="Caminho_1416" data-name="Caminho 1416" d="M20.961,170.293H16.844v-.412a5.769,5.769,0,0,0-5.764-5.764h-.412V160h.412a9.89,9.89,0,0,1,9.881,9.881Zm-3.3-.824h2.475a9.078,9.078,0,0,0-8.641-8.636v2.475A6.6,6.6,0,0,1,17.657,169.47Zm0,0" transform="translate(-10.256 -153.824)" fill="#fff" /><path id="Caminho_1417" data-name="Caminho 1417" d="M27.137,16.469H23.02v-.412A11.955,11.955,0,0,0,11.08,4.117h-.412V0h.412A16.078,16.078,0,0,1,27.137,16.057Zm-3.3-.824h2.47A15.253,15.253,0,0,0,11.491.829V3.3A12.786,12.786,0,0,1,23.838,15.645Zm0,0" transform="translate(-10.256)" fill="#fff" /></svg>
                  </a>
                  <a href="https://wa.me/5548999144599" className="link-social" title="Whatsapp">
                    <svg id="_002-whatsapp" data-name="002-whatsapp" xmlns="http://www.w3.org/2000/svg" width="17.411" height="17.41" viewBox="0 0 17.411 17.41"><path id="Caminho_1410" data-name="Caminho 1410" d="M0,17.411l1-4.936A8.553,8.553,0,0,1,.118,8.646a8.646,8.646,0,1,1,8.646,8.646,8.553,8.553,0,0,1-3.824-.89Zm5.059-1.873.129.067a7.741,7.741,0,0,0,3.577.865A7.823,7.823,0,1,0,.942,8.646a7.741,7.741,0,0,0,.865,3.577l.067.129-.818,4Zm0,0" fill="#fff" /><path id="Caminho_1411" data-name="Caminho 1411" d="M106.447,106.362h-.381a7.007,7.007,0,0,1-7-7v-.381a2.454,2.454,0,0,1,.726-1.745l.34-.34a1.439,1.439,0,0,1,1.987,0l1.065,1.06a1.4,1.4,0,0,1,.412.993,1.415,1.415,0,0,1-.412.993l-.1.1a1.075,1.075,0,0,0-.314.762,1.091,1.091,0,0,0,.314.762l.772.772a1.121,1.121,0,0,0,1.529,0l.1-.1a1.44,1.44,0,0,1,1.987,0l1.065,1.071a1.4,1.4,0,0,1,0,1.987l-.34.34A2.455,2.455,0,0,1,106.447,106.362ZM101.125,97.3a.582.582,0,0,0-.412.17l-.34.34a1.637,1.637,0,0,0-.484,1.163v.386a6.181,6.181,0,0,0,6.176,6.176h.381a1.637,1.637,0,0,0,1.163-.484l.34-.34a.582.582,0,0,0,0-.823l-1.06-1.065a.6.6,0,0,0-.824,0l-.1.1a1.9,1.9,0,0,1-2.692,0l-.772-.772a1.914,1.914,0,0,1-.556-1.348,1.894,1.894,0,0,1,.556-1.343l.1-.1a.587.587,0,0,0,.17-.412.581.581,0,0,0-.17-.412l-1.065-1.065A.582.582,0,0,0,101.125,97.3Zm0,0" transform="translate(-95.242 -92.775)" fill="#fff" /></svg>
                  </a>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

    </section>
  </>
);

export default Partial;

