import { Link } from 'gatsby';
import React from 'react';

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

const Partial = ({ }) => (
  <>
    <section id="bg-main-04" className="section">

      <div className="container">
  
        <div className="video-container">
          <iframe src="https://www.youtube.com/embed/U52iIsd65Ag?" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </section>
  </>
);

export default Partial;