import { Link } from 'gatsby';
import React from 'react';

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

const Partial = ({ }) => (
  <>
    <section className="section move-top-desktop section-ani-01">

      <img src={require('../../images/pics/rocket.png')} alt="" aria-hidden="true" className="right-top-elm ani-object-01" />

      <div className="container ha-center">

        <audio
          src="http://supernovauxculture.com/media/audio.mp3"
          preload="auto"
          id="audio-01"
          className="audio-player">
        </audio>


        <div className="columns is-multiline is-mobile">

          <div className="column is-full-mobile is-one-third-desktop">
            <div
              id="player-01"
              className="image-cta"
              style={{ ...backgroundImage(require('../../images/pics/audio-astronauta.png')) }}>
              <img src={require('../../images/pics/audio-astronauta.png')} alt="Imagem astronauta ouvir audio" />
              <button
                onClick={() => { document.getElementById('audio-01').play() }}
                data-toggle=""
                data-toggle-target="#player-01"
                type="button"
                className="image-cta-button play">
                <img src={require('../../images/pics/icon-play.png')} alt="Ícone play" />
              </button>
              <button
                onClick={() => { document.getElementById('audio-01').pause() }}
                data-toggle=""
                data-toggle-target="#player-01"
                type="button"
                className="image-cta-button pause">
                <img src={require('../../images/pics/icon-pause.png')} alt="Ícone play" />
              </button>
            </div>
          </div>

          <div className="column is-full-mobile is-one-third-desktop va-center">


            <h2 className="text is-size-5 is-secondary">
              Ouça aqui detalhes sobre a
            </h2>
            <img src={require('../../images/logos/title-jornada.svg')} alt="Jornada Supernova" className="title-jornada" />

          </div>

        </div>

        <div className="v-space-lg"></div>
        <div className="v-space-lg"></div>
        <div className="v-space-lg"></div>


        <h1 className="title">
          Está em busca de uma jornada de transformação <br/> diferente, mais completa ou mais compacta?ta?
        </h1>

        <div className="v-space-lg"></div>

        <a href="#contato" className="button is-primary is-rounded is-uppercase is-wide">
          <span className="is-uppercase">
            Fale com a gente
          </span>
        </a>

      </div>

    </section>
  </>
);

export default Partial;

