import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet";

import Header from "./header"
import Footer from "./Footer"
// import "./layout.css"
import "./styles.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div id="menu-top-01">
        <Header siteTitle={data.site.siteMetadata.title} />

        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.js"></script>

        <div
          id="parallax-body"
          className="parallax-stars"
          style={{ backgroundImage: `url(${require('../images/backgrounds/stars.svg')})` }}
          data-parallax-element="element1">
        </div>

        {children}

        <Footer />

      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
