export default () => {
  window.onscroll = fixedHeaderHandler;
  function fixedHeaderHandler() {
    if( window.pageYOffset > (25) ) {
      document.querySelector('.main-header').classList.add('is-compact');
    }
    if( window.pageYOffset < (25) ) {
      document.querySelector('.main-header').classList.remove('is-compact');
    }
  }
  fixedHeaderHandler();
};
