import { Link } from 'gatsby';
import React from 'react';

const Footer = ({}) => (
  <footer className="main-footer">

    <img src={require('../images/backgrounds/bg-dark.png')} alt="" aria-hidden="true" className="bg-full bg-raised" />

    <div className="container">

      <div className="main-footer-child visible-widescreen-true">
        <nav className="main-nav">
          <ul className="menu">
            <li className="menu-item">
              <a href="#home" className="menu-link">
                Home
              </a>
            </li>
            <li className="menu-item">
              <a href="#cronograma" className="menu-link">
                Cronograma
              </a>
            </li>
            <li className="menu-item">
              <a href="#maturidade" className="menu-link">
                Maturidade
              </a>
            </li>
            <li className="menu-item">
              <a href="#quemsomos" className="menu-link">
                Quem Somos
              </a>
            </li>
            <li className="menu-item">
              <a href="#depoimentos" className="menu-link">
                Depoimentos
              </a>
            </li>
            {/* <li className="menu-item">
              <a href="#blog" className="menu-link">
                Blog
              </a>
            </li> */}
            <li className="menu-item">
              <a href="#newsletter" className="menu-link">
                Newsletter
              </a>
            </li>
            <li className="menu-item">
              <a href="#contato" className="menu-link">
                Contato
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <div className="main-footer-child">
        <a href="http://catarinasdesign.com.br/" className="main-header-logo" target="blank">
          <img src={require('../images/logos/power-cats-white.svg')} alt="Logo powered by Catarinas" />
        </a>
      </div>

    </div>

  </footer>
);

export default Footer;

