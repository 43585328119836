export default () => {
  // Obtém a altura do body
  var bodyHeight = document.querySelector('body').offsetHeight;

  // Define a altura do parallax de acordo com a altura do body
  var parallaxBody = document.querySelector('#parallax-body');
  parallaxBody.style.height = bodyHeight + 'px';


  // // Dimensiona o background das 5 primeiras seções
  // var mainBg01 = document.querySelector('#bg-main-01').offsetHeight;
  // var mainBg02 = document.querySelector('#bg-main-02').offsetHeight;
  // var mainBg03 = document.querySelector('#bg-main-03').offsetHeight;
  // var mainBg04 = document.querySelector('#bg-main-04').offsetHeight;
  // var mainBg05 = document.querySelector('#bg-main-05').offsetHeight;

  // // Define a altura do background de acordo com o tamanho das 5 primeiras seções
  // var mainBackgroundDesktopHeight = mainBg01 + mainBg02 + mainBg03 + mainBg04 + mainBg05;
  // var bodyMainBackground = document.querySelector('#main-bg');
  // bodyMainBackground.style.height = mainBackgroundDesktopHeight + 'px';

  // Define a altura do header de informações da seção Programa
  var programaHeaderHeight = document.querySelector('#programa-header').offsetHeight;

  // Aplica um top no background da seção Programa de acordo com a altura do header
  var bgProgramaMobile = document.querySelector('#bg-programa-mobile');
  bgProgramaMobile.style.top = (programaHeaderHeight + 100) + 'px';

  // Dimensiona o background da área de planetas da seção Programa
  // var programaSectionHeight = document.querySelector('#section-programa').offsetHeight;

  // Define a altura do background da área de planetas da seção Programa
  // var bgProgramaDesktop = document.querySelector('#bg-programa-desktop');
  // bgProgramaDesktop.style.height = (programaSectionHeight + 200) + 'px';

  // // Dimensiona o background da seção Catarinas
  // var catarinasSectionHeight = document.querySelector('#section-catarinas').offsetHeight;

  // // Define a altura do background da seção Catarinas
  // var bgCatarinasDesktop = document.querySelector('#bg-catarinas');
  // bgCatarinasDesktop.style.height = catarinasSectionHeight + 'px';
};
