import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Hero from './partials/hero';
import Mindset from './partials/mindset';
import Jornada from './partials/jornada';
import Cronograma from './partials/cronograma';

import Precisa from './partials/precisa';
import Maturidade from './partials/maturidade';
import Programa from './partials/programa';
import Catarinas from './partials/catarinas';
import Depoimentos from './partials/depoimentos';
import Content from './partials/content';
import Newsletter from './partials/newsletter';
import Contato from './partials/contato';


import swiperInit from '../scripts/swiper';
import parallaxInit from '../scripts/parallax.js';
import backgroundsInit from '../scripts/backgrounds.js';
import changestateInit from '../scripts/changestate.js';
import animationsInit from '../scripts/animations.js';
import fixedHeaderInit from '../scripts/fixedHeader.js';
import smoothAnchorInit from '../scripts/smooth-anchor.js';



export default class IndexPage extends React.Component {

  componentDidMount() {
    backgroundsInit();
    swiperInit();
    parallaxInit();
    changestateInit();
    animationsInit();
    fixedHeaderInit();
    smoothAnchorInit();
  }

  render () {
    return (
      <>
        <Layout>
          <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />


          <main role="main">
            <div
              className="main-bg"
              style={{
                backgroundImage: `url(${require('../images/backgrounds/bg-main-desktop.png')})`
              }}>

              <Hero />

              <Mindset />

              <Jornada />

              <Cronograma />

            </div>

            <Precisa />

            <Maturidade />

            <Programa />

            <Catarinas />

            <Depoimentos />

            {/* <Content /> */}

            <Newsletter />

            <Contato />

          </main>

        </Layout>

        <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/bfe3d016-c264-4b58-acb5-5a8ecd49c9fa-loader.js"></script>
      </>
    );
  }
};
