export default () => {

  const el = function(selector) {
    if(!(this instanceof el)) return new el(selector);
    this.selector = selector;
    if (this.info()) {
      this.top = this.info().top;
      this.bottom = this.info().bottom;
      this.width = this.info().width;
      this.height = this.info().height;
    }
  }

  el.prototype = {
    me: function() {
      if (!document.querySelector(this.selector)) return;
      return document.querySelectorAll(this.selector).length > 1 ? document.querySelectorAll(this.selector) : document.querySelector(this.selector);
    },
    info: function() {
      if (!document.querySelector(this.selector)) return;
      return document.querySelector(this.selector).getBoundingClientRect();
    }
  }

  window.requestAnimationFrame = window.requestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.msRequestAnimationFrame
    || function(f) { setTimeout(f, 1000/60); }

    var bubble1 = el('[data-parallax-element="element1"]');

    function parallaxbubbles() {
     if (!bubble1.me()) return;
     var scrolltop = window.pageYOffset;
     bubble1.me().style.bottom = (40-(scrolltop * .1)) + 'px';
    }

    window.addEventListener('scroll', function() {
     requestAnimationFrame(parallaxbubbles);
    }, false);

    console.log('parallax o/');

  };
