import { Link } from 'gatsby';
import React from 'react';

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

const Partial = ({}) => (
  <>
    <a id="cronograma"></a>
    <section  id="bg-main-05" className="section is-02">

      <img src={require('../../images/backgrounds/bg-02-mobile.png')} alt="" aria-hidden="true" className="bg-02-mobile" />

      <div className="section-detail">
        Cronograma
      </div>

      <div className="container">

        <div className="columns is-mobile">

          <div className="column is-one-thirds-desktop visible-desktop-true"></div>

          <div className="column is-full-mobile is-two-thirds-desktop">

            <p className="text is-size-6 is-size-3-widescreen is-white has-text-right">
              Entenda quais fases compõem a jornada Supernova
            </p>

            <h1 className="title is-uppercase is-large is-white has-text-right">
              Cronograma
            </h1>

          </div>

        </div>

        <div className="v-space-lg"></div>

        <div className="swiper-container swiper-container-cronograma">
          <div className="swiper-wrapper">
            <div className="swiper-slide is-flex-center">
              <div className="ha-center">
                <span className="tag is-large is-rounded is-step">
                  Diagnóstico
                </span>
                <div className="v-space-sm"></div>
                <span className="tag is-large is-rounded is-step">
                  Palestra de Sensibilização
                </span>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="schedule-component">
                <div className="schedule-component-wrapper">
                  <div className="schedule-component-label">
                    Semana
                    <span className="schedule-component-counter">
                      1
                    </span>
                  </div>
                  <h3 className="schedule-component-header">
                    Workshop 1
                  </h3>
                  <p className="schedule-component-content">
                    Hard skills ligadas ao conceito e processo de CX e UX
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="schedule-component">
                <div className="schedule-component-wrapper">
                  <div className="schedule-component-label">
                    Semana
                    <span className="schedule-component-counter">
                      2
                    </span>
                  </div>
                  <h3 className="schedule-component-header">
                    Missão 1
                  </h3>
                  <p className="schedule-component-content">
                  Vivências de UX research para descobertas e validações de hipóteses


                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="schedule-component">
                <div className="schedule-component-wrapper">
                  <div className="schedule-component-label">
                    Semana
                    <span className="schedule-component-counter">
                      3
                    </span>
                  </div>
                  <h3 className="schedule-component-header">
                    Workshop 2
                  </h3>
                  <p className="schedule-component-content">
                    Team building, soft skills e transformação de cultura UX
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="schedule-component">
                <div className="schedule-component-wrapper">
                  <div className="schedule-component-label">
                    Semana
                    <span className="schedule-component-counter">
                      4
                    </span>
                  </div>
                  <h3 className="schedule-component-header">
                    Missão 2
                  </h3>
                  <p className="schedule-component-content">
                    Facilitação e co-criação multidisciplinar para pensar fora da caixa
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="schedule-component">
                <div className="schedule-component-wrapper">
                  <div className="schedule-component-label">
                    Semana
                    <span className="schedule-component-counter">
                      5
                    </span>
                  </div>
                  <h3 className="schedule-component-header">
                    Mentoria
                  </h3>
                  <p className="schedule-component-content">
                    acompanhamento e mentoria remota para times e influencers
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="schedule-component">
                <div className="schedule-component-wrapper">
                  <div className="schedule-component-label">
                    Semana
                    <span className="schedule-component-counter">
                      6
                    </span>
                  </div>
                  <h3 className="schedule-component-header">
                    Missão 3
                  </h3>
                  <p className="schedule-component-content">
                    Aprimoramento do pensamento lean com prototipação e validações
                  </p>
                </div>
              </div>
            </div>
            <div className="swiper-slide is-flex-center">
              <span className="tag is-large is-rounded is-step">
                Fechamento
              </span>
            </div>
          </div>
          <div className="swiper-nav">
            <div className="swiper-button-next">
              <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" className="svg-inline--fa fa-angle-right fa-w-6"><path fill="currentColor" d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z" className=""></path></svg>
            </div>
            <div className="swiper-button-prev">
              <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="angle-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" className="svg-inline--fa fa-angle-left fa-w-6"><path fill="currentColor" d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z" className=""></path></svg>
            </div>
          </div>
        </div>

      </div>
    </section>
  </>
);

export default Partial;

