import { Link } from 'gatsby';
import React from 'react';

const TopNav = ({}) => (

  <div className="top-nav visible-widescreen-false">
    <button className="top-nav-button open" data-toggle data-toggle-target="#menu-top-01" >
      <svg className="icon-open" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="bars" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" ><path fill="currentColor" d="M442 114H6a6 6 0 0 1-6-6V84a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6zm0 160H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h436a6 6 0 0 1 6 6v24a6 6 0 0 1-6 6z" className=""></path></svg>
      <svg className="icon-close" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="times" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" ><path fill="currentColor" d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z" className=""></path></svg>
    </button>

    <div className="top-nav-menu-content">
      <div
        className="top-nav-bg"
        style={{
          backgroundImage: `url(${require('../images/backgrounds/bg-menu-mobile.png')})`
        }}>
      </div>
      <ul className="top-nav-menu">
        <li className="top-nav-menu-item">
          <a href="#home" className="top-nav-menu-link click-inside">
            Home
          </a>
        </li>
        <li className="top-nav-menu-item">
          <a href="#cronograma" className="top-nav-menu-link click-inside">
            Cronograma
          </a>
        </li>
        <li className="top-nav-menu-item">
          <a href="#maturidade" className="top-nav-menu-link click-inside">
            Maturidade
          </a>
        </li>
        <li className="top-nav-menu-item">
          <a href="#quemsomos" className="top-nav-menu-link click-inside">
            Quem Somos
          </a>
        </li>
        <li className="top-nav-menu-item">
          <a href="#depoimentos" className="top-nav-menu-link click-inside">
            Depoimentos
          </a>
        </li>
        {/* <li className="top-nav-menu-item">
          <a href="#blog" className="top-nav-menu-link click-inside">
            Blog
          </a>
        </li> */}
        <li className="top-nav-menu-item">
          <a href="#newsletter" className="top-nav-menu-link click-inside">
            Newsletter
          </a>
        </li>
        <li className="top-nav-menu-item">
          <a href="#contato" className="top-nav-menu-link click-inside">
            Contato
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default TopNav;

