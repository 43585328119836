import { Link } from 'gatsby';
import React from 'react';
import axios from 'axios'
import InputMask from 'react-input-mask';
import ReactNotification from "react-notifications-component";

import "react-notifications-component/dist/theme.css";



const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

class Partial extends React.Component {
  constructor(props) {
    super(props);


    this.addNotification = this.addNotification.bind(this);
    this.notificationDOMRef = React.createRef();

    this.state = {
      nome: '',
      email: '',
      validatedMail: false,
      telefone: '',
      empresa: '',
      funcionarios: '-1',
      designers: '-1',
    }
  }

  componentDidUpdate() {
    console.log(this.state)
  }

  addNotification(title, text, type) {
    this.notificationDOMRef.current.addNotification({
      title: title,
      message: text,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true }
    });
  }

  render() {

    return (
      <>
        <ReactNotification ref={this.notificationDOMRef} />
        <a id="contato"></a>
        <section className="section is-03">

          <div className="section-detail is-03">
            Contatos
          </div>

          <img src={require('../../images/backgrounds/bg-contact.png')} alt="" aria-hidden="true" className="bg-full bg-raised" />

          <div className="container ha-center is-xsmall">

            <h1 className="title is-white is-600">
              Quero transformar <br />
              minha empresa
            </h1>

            <div className="v-space-lg"></div>

            <form className="form" name="supernova-form-contato">
              <div className="field">
                <label className="label is-assistive">Nome</label>
                <div className="control">
                  <input name="name" className="input is-white is-inverted" type="text" placeholder="Nome" value={this.state.nome} onChange={(e) => this.onChange({ type: 'nome', value: e.target.value })} />
                </div>
              </div>
              <div className="field">
                <label className="label is-assistive">Email</label>
                <div className="control">
                  <input name="email" className="input is-white is-inverted" type="text" placeholder="Email" value={this.state.email} onChange={(e) => this.onChange({ type: 'email', value: e.target.value })} />
                </div>
              </div>
              <div className="field">
                <label className="label is-assistive">Telefone</label>
                <div className="control">
                  <InputMask
                    mask="(99) 9 9999-9999"
                    className="input is-white is-inverted"
                    placeholder="Telefone"
                    name="personal_phone"
                    value={this.state.telefone} onChange={(e) => this.onChange({ type: 'telefone', value: e.target.value })} />
                </div>
              </div>
              <div className="field">
                <label className="label is-assistive">Empresa</label>
                <div className="control">
                  <input name="company_name" className="input is-white is-inverted" type="text" placeholder="Empresa" value={this.state.empresa} onChange={(e) => this.onChange({ type: 'empresa', value: e.target.value })} />
                </div>
              </div>
              <div className="field">
                <label className="label is-assistive">Quantidade de funcionários</label>
                <div className="control">
                  <div className="select is-white is-inverted">
                    <select name="Field 4 null" onChange={(e) => this.onChange({ type: 'funcionarios', value: e.target.value })} value={this.state.funcionarios}>
                      <option value="">Quantidade de funcionários</option>
                      <option value='0-50'>0 - 50</option>
                      <option value='51-100'>51 - 100</option>
                      <option value='100+'>Mais de 100</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="field">
                <label className="label is-assistive">Quantidade de designers</label>
                <div className="control">
                  <div className="select is-inverted">
                    <select name="Field 5 null" onChange={(e) => this.onChange({ type: 'designers', value: e.target.value })} value={this.state.designers}>
                      <option value="">Possui quantos designers na empresa?</option>
                      <option value='0'>Nenhum</option>
                      <option value='1'>1 Designer</option>
                      <option value='2'>2 Designers</option>
                      <option value='3'>3 Designers</option>
                      <option value='4'>4 Designers</option>
                      <option value='4+'>Mais de 4 Designers</option>
                    </select>
                  </div>
                </div>
              </div>

              <footer className="form-footer">
                <button
                  type="submit" className="button is-primary is-rounded is-uppercase is-full" onClick={(e) => this.sendMail(e)}
                  disabled={
                    this.state.nome !== '' &&
                    this.state.email !== '' &&
                    this.state.telefone !== '' &&
                    this.state.empresa !== '' &&
                    this.state.funcionarios !== '' &&
                    this.state.designers !== '' &&
                    this.state.validatedMail !== false
                    ?
                    false : true
                  }>
                  <span className="is-uppercase">
                    Enviar
                  </span>
                </button>
              </footer>

            </form>

          </div>

        </section>
      </>
    )
  }

  onChange(e) {
    const { type, value } = e
    switch (type) {
      case 'nome':
        this.setState({ nome: value });
        break;
      case 'email':
        const validatedMail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/.test(value)
        this.setState({
          email: value,
          validatedMail
        });
        break;
      case 'telefone':
        this.setState({ telefone: value });
        break;
      case 'empresa':
        this.setState({ empresa: value });
        break;
      case 'funcionarios':
        this.setState({ funcionarios: value });
        break;
      case 'designers':
        this.setState({ designers: value });
        break;
    }
  }

  sendMail(e) {

    e.preventDefault();

    const formdata = new FormData;

    formdata.append('nome', this.state.nome);
    formdata.append('email', this.state.email);
    formdata.append('telefone', this.state.telefone);
    formdata.append('empresa', this.state.empresa);
    formdata.append('funcionarios', this.state.funcionarios);
    formdata.append('designers', this.state.designers);

    axios({
      method: 'post',
      url: 'http://stage.workandcode.com/mail-server-catarinas/send.php',
      data: formdata
    }).then(resp => {
      if (resp.data === 'ok') {
        const text = 'Formulário enviado com sucesso!';
        const type = 'success';
        this.addNotification(null, text, type);
        this.setState({
          nome: '',
          email: '',
          telefone: '',
          empresa: '',
          funcionarios: '',
          designers: '',
          validatedMail: false
        });
      } else {
        const text = 'Ocorreu um erro ao tentar enviar o formulário.';
        const type = 'danger';
        this.addNotification(null, text, type);
      }
    });

  }
}

export default Partial;

