import { Link } from 'gatsby';
import React from 'react';

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

const Partial = ({}) => (
  <>
    <a id="home"></a>
    <section id="bg-main-02" className="hero">
      <div className="hero-body">
        <div className="container">

          <div className="columns is-mobile">

            <div className="column is-full-mobile is-half-desktop">

              <div className="v-space-lg"></div>

              <h1 className="title">
                <figure
                  className="image is-logo-hero is-marginless"
                  style={{ ...backgroundImage(require('../../images/logos/logo-hero.svg')) }}>
                  <img src={require('../../images/logos/logo-hero.svg')} alt="" />
                </figure>
              </h1>

              <div className="v-space-lg"></div>

              <h2 className="text is-size-5 is-secondary">
              Um programa de 6 semanas intensas de imersão e práticas do universo do user experience design aplicado a problemas reais para transformar a cultura de UX da sua empresa.
              </h2>

              <div className="v-space-lg"></div>

              <a href="#contato" className="button is-uppercase is-rounded is-link">
                <span>
                  Quero transformar minha empresa
                </span>
              </a>

            </div>

          </div>

        </div>
      </div>
    </section>
  </>
);

export default Partial;

