import { Link } from 'gatsby';
import React from 'react';

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

const Partial = ({ }) => (
  <>
    <a id="depoimentos"></a>
    <section className="section is-04">

      <img src={require('../../images/backgrounds/bg-bottom-temp.png')} alt="" aria-hidden="true" className="bg-bottom-temp" />


      <div className="container">

        <div className="columns is-desktop is-variable is-6">

          <div className="column is-full-mobile is-7-desktop">

            <div className="left-wrapper">
              <h1 className="title is-size-1 is-uppercase has-text-right">
                <span className="is-400">O que</span> Falam
              </h1>

              <br />

              <p className="text is-size-2 has-text-right">
                por aí
              </p>
            </div>


            <div className="v-space-xlg visible-desktop-true"></div>

            <div className="v-space"></div>

            <p className="text is-size-4">
              O envolvimento das Cats foi total, levantam todas as dores da empresa com as pessoas, identificam os valores declarados e os praticados (muitas vezes divergentes) e nos levaram em uma jornada que Todos da Vista compraram. Todos adoraram. Dai saiu realmente uma outra empresa. Eu não poderia imaginar que o resultado fosse tão transformador.
            </p>

            <div className="v-space"></div>

            <p className="text is-size-4">
              Conseguiram o envolvimento/participação de todos. Saímos energizados com sangue nos olhos querendo mudar o mundo, e sabendo que só dependia de nós pra tal.
            </p>

            <div className="v-space"></div>

            <p className="text is-size-4">
              A execução, por parte delas foi excepcional.
            </p>

            <div className="v-space-lg"></div>

            <p className="text is-size-4 is-700">
              Vilson Moesch - Vista
            </p>

            <p className="text is-size-5 is-uppercase is-primary">
              CEO
            </p>

          </div>

          <div className="column is-full-mobile is-5-desktop">

            <div className="testimonial-component">

              <img src={require('../../images/pics/quotes-top.svg')} alt="Aspas" aria-hidden="true" className="quotes-top" />

              <img src={require('../../images/pics/quotes-bottom.svg')} alt="Aspas" aria-hidden="true" className="quotes-bottom" />

              <div className="testimonial-card">
                <p className="text">
                  "
                  Para mim, foi muito bom e de grande aprendizado. Vejo hoje uma grande parte da empresa engajada nessa mudança de paradigma.
                  "
                </p>
              </div>

              <div className="testimonial-card">
                <p className="text">
                  "
                  Os treinamentos foram ministrados da melhor maneira possível, agregando conhecimento sobre áreas que eram até o momento desconhecidas, estimulando a interação entre as equipes e consequentemente a aproximação das pessoas.
                  "
                </p>
              </div>

              <div className="testimonial-card">
                <p className="text">
                  "
                  Muito bacana, conseguimos ver os processos de outros setores que não tínhamos nem ideia de como funcionava, a integração e união dos setores para um bem comum é muito massa, só elogios!
                  "
                </p>
              </div>

              <div className="testimonial-card">
                <p className="text">
                  "
                  Minha percepção é que está sendo um movimento revolucionador, onde o compartilhamento de informações e uma visão 360 da empresa, são o ponto principal.
                  "
                </p>
              </div>

            </div>

          </div>

        </div>

      </div>

    </section>
  </>
);

export default Partial;
