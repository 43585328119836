import { Link } from 'gatsby';
import React from 'react';
import axios from 'axios'
import InputMask from 'react-input-mask';
import ReactNotification from "react-notifications-component";

import "react-notifications-component/dist/theme.css";

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

class Partial extends React.Component {
  constructor(props) {
    super(props);


    this.addNotification = this.addNotification.bind(this);
    this.notificationDOMRef = React.createRef();

    this.state = {
      nome: '',
      email: '',
      validatedMail: false,
    }
  }

  componentDidUpdate() {
    // console.log(this.state)
  }

  addNotification(title, text, type) {
    this.notificationDOMRef.current.addNotification({
      title: title,
      message: text,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: { duration: 2000 },
      dismissable: { click: true }
    });
  }

  render() {
    return (
      <>
        <ReactNotification ref={this.notificationDOMRef} />

        <a id="newsletter"></a>
        <section className="section is-06">

          <div className="section-detail is-02 is-center" aria-hidden="true">
            Newsletter
          </div>

          <img src={require('../../images/backgrounds/bg-newsletter-top.png')} alt="" aria-hidden="true" className="bg-top" />

          <div className="container ha-center is-xsmall-mobile">

            <h1 className="title">
              Assine nossa Supernovas e <br />
              receba nossos conteúdos
            </h1>

            <div className="v-space-lg"></div>

            <form name="supernova-form-newsletter">
              <div className="columns is-desktop has-tiny-gap">
                <div className="column is-full-mobile is-one-quarter-desktop">
                  <div className="field">
                    <label className="label is-assistive">Nome</label>
                    <div className="control">
                      <input className="input" name="name" type="text" placeholder="Nome" value={this.state.nome} onChange={(e) => this.onChange({ type: 'nome', value: e.target.value })} />
                    </div>
                  </div>
                </div>
                <div className="column is-full-mobile is-two-quarters-desktop">
                  <div className="field">
                    <label className="label is-assistive">Email</label>
                    <div className="control">
                      <input className="input" name="email" type="text" placeholder="Email" value={this.state.email} onChange={(e) => this.onChange({ type: 'email', value: e.target.value })} />
                    </div>
                  </div>
                </div>
                <div className="column is-full-mobile is-one-quarter-desktop">
                  <button type="submit" className="button is-primary is-rounded is-uppercase is-full" onClick={(e) => this.sendMail(e)}
                    disabled={
                      this.state.nome !== '' &&
                        this.state.email !== '' &&
                        this.state.validatedMail !== false
                        ? false
                        : true
                    }>
                    <span className="is-uppercase">
                      Receber
                    </span>
                  </button>
                </div>
              </div>
            </form>

          </div>

        </section>
      </>
    )
  }

  onChange(e) {
    const { type, value } = e
    switch (type) {
      case 'nome':
        this.setState({ nome: value });
        break;
      case 'email':
        const validatedMail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/.test(value)
        this.setState({
          email: value,
          validatedMail
        });
        break;
    }
  }

  sendMail(e) {

    e.preventDefault();

    const text = 'Seu email foi registrado com sucesso!'
    const type = 'success'

    setTimeout(() => {
      this.addNotification(null, text, type);
      this.setState({ nome: '', email: '', validatedMail: false });
    }, 800);

  }
}

export default Partial;
