import Swiper from 'swiper';

export default () => {
  var swiperCards = new Swiper('.swiper-container-cards', {
    slidesPerView: 3,
    spaceBetween: 30,

    breakpoints: {

      1024: {
        slidesPerView: 2,
      },
      700: {
        slidesPerView: 1,
      },
    }
  });
  var swiperCronograma = new Swiper('.swiper-container-cronograma', {
    slidesPerView: 4,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      1280: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 2,
      },
      668: {
        slidesPerView: 1,
      }
    }
  });
}; 
