import { Link } from 'gatsby';
import React from 'react';

const backgroundImage = (path) => ({ backgroundImage: `url(${path})` });

const Partial = ({ }) => (
  <>
    <section id="section-programa" className="section is-03">

      <img
        src={require('../../images/backgrounds/bg-programa-mobile.png')}
        alt=""
        aria-hidden="true"
        id="bg-programa-mobile"
        className="bg-programa-mobile visible-desktop-false"
      />

      <img
        src={require('../../images/backgrounds/bg-programa-desktop.png')}
        alt=""
        aria-hidden="true"
        id="bg-programa-desktop"
        className="bg-programa-desktop visible-desktop-true"
      />

      <div className="container">

        <div className="columns is-desktop">
          <div id="programa-header" className="column is-full-mobile is-half-desktop va-center">
            <div className="programs-content-wrapper">
              <h1 className="text is-size-4">
                Através do programa
              </h1>

              <div className="v-space-sm"></div>

              <img src={require('../../images/logos/title-supernova.svg')} alt="Supernova" className="title-supernova" />

              <div className="v-space-sm"></div>

              <p className="text is-size-4">
                Ajudamos o seu time na busca pelo nível
                de maturidade do "UX Distribuído"
              </p>

              <div className="v-space"></div>

              <a href="#contato" className="button is-primary is-rounded is-uppercase is-wide">
                <span className="is-uppercase">
                  Fale com a gente
                </span>
              </a>

              <div className="v-space-xlg visible-desktop-false"></div>

            </div>
          </div>
          <div className="column is-full-mobile is-half-desktop va-center">

            <div className="v-space-xxxxlg visible-desktop-false"></div>

            <div className="arrange-container">

              <div className="media ani-object-02">
                <div className="media-left">
                  <figure
                    className="image is-planet is-01"
                    style={{ ...backgroundImage(require('../../images/pics/planet-01.svg')) }}>
                    <img src={require('../../images/pics/planet-01.svg')} alt="Imagem Ignorado" />
                  </figure>
                  <span
                    className="dialog-number"
                    style={{ ...backgroundImage('./images/pics/dialog-number.svg') }}>
                    1
                  </span>
                </div>
                <div className="media-content">

                  <h3 className="title is-size-4 is-uppercase is-white is-800">
                    Ignorado
                  </h3>

                  <div className="v-space-xsm"></div>

                  <ul className="list-featured">
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Design = Visual?
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Usuário nunca é consultado
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Design no fim do processo
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="media ani-object-03">
                <div className="media-left">
                  <figure
                    className="image is-planet is-01"
                    style={{ ...backgroundImage(require('../../images/pics/planet-02.svg')) }}>
                    <img src={require('../../images/pics/planet-02.svg')} alt="Imagem Ignorado" />
                  </figure>
                  <span
                    className="dialog-number"
                    style={{ ...backgroundImage('./images/pics/dialog-number.svg') }}>
                    2
                  </span>
                </div>
                <div className="media-content">

                  <h3 className="title is-size-4 is-uppercase is-white is-800">
                    Discutindo
                  </h3>

                  <div className="v-space-xsm"></div>

                  <ul className="list-featured">
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        UX apenas no discurso
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Decisões baseadas na visão do dev sobre o usuário
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="media ani-object-04">
                <div className="media-left">
                  <figure
                    className="image is-planet is-01"
                    style={{ ...backgroundImage(require('../../images/pics/planet-03.svg')) }}>
                    <img src={require('../../images/pics/planet-03.svg')} alt="Imagem Ignorado" />
                  </figure>
                  <span
                    className="dialog-number"
                    style={{ ...backgroundImage('./images/pics/dialog-number.svg') }}>
                    3
                  </span>
                </div>
                <div className="media-content">

                  <h3 className="title is-size-4 is-uppercase is-white is-800">
                    Aderindo
                  </h3>

                  <div className="v-space-xsm"></div>

                  <ul className="list-featured">
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Especialista UX interno ou externo
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Usuários finalmente consultados
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Sem processo ou budget definido Alto risco de regressão
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="media ani-object-05">
                <div className="media-left">
                  <figure
                    className="image is-planet is-01"
                    style={{ ...backgroundImage(require('../../images/pics/planet-04.svg')) }}>
                    <img src={require('../../images/pics/planet-04.svg')} alt="Imagem Ignorado" />
                  </figure>
                  <span
                    className="dialog-number"
                    style={{ ...backgroundImage('./images/pics/dialog-number.svg') }}>
                    4
                  </span>
                </div>
                <div className="media-content">

                  <h3 className="title is-size-4 is-uppercase is-white is-800">
                    Realizando
                  </h3>

                  <div className="v-space-xsm"></div>

                  <ul className="list-featured">
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Processo e atividades definidos
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Papéis claros e budget definido
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Envolvimento de outras áreas
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Liderança em UX
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Acompanhamento de métricas
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="media ani-object-06">
                <div className="media-left">
                  <figure
                    className="image is-planet is-01"
                    style={{ ...backgroundImage(require('../../images/pics/planet-05.svg')) }}>
                    <img src={require('../../images/pics/planet-05.svg')} alt="Imagem Ignorado" />
                  </figure>
                  <span
                    className="dialog-number"
                    style={{ ...backgroundImage('./images/pics/dialog-number.svg') }}>
                    5
                  </span>
                </div>
                <div className="media-content">

                  <h3 className="title is-size-4 is-uppercase is-white is-800">
                    Distribuído
                  </h3>

                  <div className="v-space-xsm"></div>

                  <ul className="list-featured">
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Toda a jornada é pensada
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Preocupação com UX e CX em todos os níveis hierárquicos
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        ROI de UX mensurado
                      </p>
                    </li>
                    <li className="list-featured-item">
                      <p className="text is-white is-size-6">
                        Mentalidade de UX presente na diretoria e decisões estratégicas
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

            </div>


          </div>
        </div>


      </div>

    </section>
  </>
);

export default Partial;

